export const maxBeneficiarios = 2;

export const contenidoCards = [
  {
    titulo: 'Cotiza al instante',
    subtitulo: 'Conoce la oferta digital de seguro en Costa Rica.',
  },
  {
    titulo: 'Ahorra tiempo y dinero',
    subtitulo:
      'En pocos clics, obtén y compara coberturas y precios de paquetes diseñados por nuestro equipo técnico.',
  },
  {
    titulo: 'Tu seguro en línea',
    subtitulo:
      'Disfruta de una nueva experiencia En la compra y servicio postventa de tu seguro.',
  },
  {
    titulo: 'Asesoría gratuita',
    subtitulo:
      'Con el respaldo y servicio de UNISERSE, empresa líder del mercado asegurador costarricense.',
  },
];

export const rutasCotizar = [
  {
    codigo: 'SEG-DA',
    ruta: '/cotizar-deducible-vehiculo',
  },
  {
    codigo: 'SEG-V',
    ruta: '/cotizar-seguro-viaje',
  },
  {
    codigo: 'SEG-AEG',
    ruta: '/cotizar-enfermedades-graves',
  },
  {
    codigo: 'SEG-VID',
    ruta: '/cotizar-vida-plus',
  },
  {
    codigo: 'SEG-FUNE',
    ruta: '/cotizar-funerario-plus',
  },
  {
    codigo: 'SEG-ONC',
    ruta: '/cotizar-seguro-oncologico-plus',
  },
  {
    codigo: 'SEG-PLENI',
    ruta: '/cotizar-seguro-plenisalud',
  },
  {
    codigo: 'SEG-PM',
    ruta: '/cotizar-seguro-proteccion-multiple',
  },
];
export const mesArray = [
  { value: '01', label: 'Enero' },
  { value: '02', label: 'Febrero' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Mayo' },
  { value: '06', label: 'Junio' },
  { value: '07', label: 'Julio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Septiembre' },
  { value: '10', label: 'Octubre' },
  { value: '11', label: 'Noviembre' },
  { value: '12', label: 'Diciembre' },
];

export const informativaSeguro = [
  {
    codigo: 'SEG-DA',
    titulo: 'Ahorra en cada \\n reclamo de tu vehículo',
    descripcion:
      'Disfruta de la libertad de conducir \\n sin preocupaciones financieras ante cualquier siniestro.',
    texto:
      'Maneja sin preocupaciones, protégete financieramente y disfruta de la tranquilidad en caso de sufrir un accidente o daño a tu vehículo.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] leading-7 lg:leading-10 color-primary text-center lg:text-[35px] xl:text-left">
        Necesario para <br /> protegerte en caso de accidente
      </h3>
    ),
  },
  {
    codigo: 'SEG-V',
    titulo: 'Viaja sin preocupaciones, \\n y disfruta de tus aventuras',
    descripcion:
      'Cubre cualquier inconveniente durante tu viaje, \\n desde cancelaciones hasta pérdida de equipaje y más.',
    texto:
      'Incluyendo cobertura médica, cancelación \n de viaje, pérdida de equipaje y asistencia las 24 horas.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] leading-8 lg:leading-10 color-primary text-center lg:text-[35px] xl:text-left">
        Necesario para protección <br /> y tranquilidad durante tus viajes
      </h3>
    ),
  },
  {
    codigo: 'SEG-AEG',
    titulo: 'Cuida de tu bolsillo \\n ante enfermedades graves',
    descripcion:
      'Obtén la seguridad y el respaldo financiero \\n que necesitas para afrontar cualquier diagnóstico desafiante.',
    texto:
      'Te brindará la seguridad financiera y tranquilidad ante \n altos costos médicos y tratamientos prolongados durante la enfermedad.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] color-primary text-center leading-8 lg:leading-10 lg:text-[35px] xl:text-left">
        Necesario para <br /> enfocarte en tu recuperación
      </h3>
    ),
  },
  {
    codigo: 'SEG-ONC',
    titulo: ' Evita preocupaciones económicas \\n en tratamiento oncológico  ',
    descripcion:
      'Cuando el cáncer llega, un seguro oncólogico te respalda, \\n asegurando tu tratamiento y tranquilidad en tiempos difíciles.',
    texto:
      'Te brindará la seguridad financiera y tranquilidad ante \n altos costos médicos y tratamientos prolongados durante la enfermedad.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] color-primary text-center leading-8 lg:leading-10 lg:text-[20px] lg:text-left xl:text-[35px] xl:mt-14">
        Necesario para protección <br /> financiera por tratamientos de cáncer
      </h3>
    ),
  },
  {
    codigo: 'SEG-PLENI',
    titulo: ' Disfruta tu salud con \\n la tranquilidad que mereces  ',
    descripcion:
      'El seguro médico digital para cuidar de toda la familia: \\n Red de Especialistas y Red Primaria sin costos adicionales.',
    texto:
      'PleniSalud te brinda un seguro médico sin deducibles, con acceso a una red \n primaria de salud sin costo, indemnización 100% digital, y servicio de medicina \n virtual ilimitado para tu tranquilidad y la de tu familia.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] color-primary text-center leading-8 lg:leading-10 lg:text-[20px] lg:text-left xl:text-[35px] xl:mt-14">
        Protección Integral sin Deducibles y <br /> con Servicios Digitales.
      </h3>
    ),
  },
  {
    codigo: 'SEG-VID',
    titulo: 'Viaja sin preocupaciones, \\n y disfruta de tus aventuras',
    descripcion:
      'Cubre cualquier inconveniente durante tu viaje, \\n desde cancelaciones hasta pérdida de equipaje y más.',
    texto:
      'Incluyendo cobertura médica, cancelación \n de viaje, pérdida de equipaje y asistencia las 24 horas.',
    necesario: (
      <h3 className="fontPSemiBold text-[22px] leading-8 lg:leading-10 color-primary text-center lg:text-[35px] xl:text-left">
        Necesario para protección <br /> y tranquilidad durante tus viajes
      </h3>
    ),
  },
];

export const parentescoArray = [
  { value: '020', label: 'Conyugue' },
  { value: '030', label: 'Hijos' },
  { value: '040', label: 'Madre' },
  { value: '050', label: 'Padre' },
  { value: '999', label: 'Otro' },
];

export const paisesList = [
  { value: 'América del Norte', label: 'América del Norte' },
  { value: 'América del Sur', label: 'América del Sur' },
  { value: 'América Central', label: 'América Central' },
  { value: 'Europa', label: 'Europa' },
  { value: 'África', label: 'África' },
  { value: 'Asia', label: 'Asia' },
  { value: 'Oceanía', label: 'Oceanía' },
];

export const tiposIdentificaciones = [
  {
    id: '0',
    label: 'Cédula Física Nacional',
  },
  {
    id: '4',
    label: 'Cédula Institución Autónoma',
  },
  {
    id: '6',
    label: 'Documento Único',
  },
  {
    id: '3',
    label: 'Cédula Persona Jurídica Nacional',
  },
  {
    id: '8',
    label: 'Permiso de Trabajo',
  },
  {
    id: '1',
    label: 'Cédula de Residencia',
  },
  {
    id: '10',
    label: 'Residente Régimen Excepcional',
  },
  {
    id: '9',
    label: 'Pasaporte',
  },
  {
    id: '7',
    label: 'Cédula Jurídica Empresa Extranjera',
  },
  {
    id: '11',
    label: 'Cédula Refugiados',
  },
  {
    id: '2',
    label: 'Cédula Jurídica Gobierno Central',
  },
  {
    id: '5',
    label: 'Documento Migratorio',
  },
];

//generar un array de años del año actual hasta 15 años atras
const fecha = new Date();
const year = fecha.getFullYear();
const yearArray = [];
for (let i = year; i >= year - 100; i--) {
  yearArray.push({ value: i, label: i });
}
export const yearCarrosArray = yearArray;

export const marcaVehiculosSelect = [
  { label: "Abarth", value: "abarth" },
  { label: "AC Cars", value: "ac cars" },
  { label: "Acura", value: "acura" },
  { label: "Adamastor Sports Cars", value: "adamastor sports cars" },
  { label: "Alfa Romeo", value: "alfa romeo" },
  { label: "Alpina", value: "alpina" },
  { label: "Alpine", value: "alpine" },
  { label: "Apollo Automobil", value: "apollo automobil" },
  { label: "Aston Martin", value: "aston martin" },
  { label: "Audi", value: "audi" },
  { label: "Beijing Automotive Group", value: "beijing automotive group" },
  { label: "Bentley", value: "bentley" },
  { label: "Bitter Cars", value: "bitter cars" },
  { label: "BMW", value: "bmw" },
  { label: "Brabham Automotive", value: "brabham automotive" },
  { label: "Brabus", value: "brabus" },
  { label: "Briggs Automotive Company", value: "briggs automotive company" },
  { label: "Brilliance Auto Group", value: "brilliance auto group" },
  { label: "Bristol Cars", value: "bristol cars" },
  { label: "Bugatti", value: "bugatti" },
  { label: "Buick", value: "buick" },
  { label: "BYD Auto", value: "byd auto" },
  { label: "Cadillac", value: "cadillac" },
  {
    label: "Carroll Shelby International",
    value: "carroll shelby international",
  },
  { label: "Caterham Cars", value: "caterham cars" },
  { label: "Changan Motors", value: "changan motors" },
  { label: "Chery", value: "chery" },
  { label: "Chevrolet", value: "chevrolet" },
  { label: "Chrysler", value: "chrysler" },
  { label: "Citroën", value: "citroen" },
  { label: "Coda Automotive", value: "coda automotive" },
  { label: "Comarth", value: "comarth" },
  { label: "Cupra", value: "cupra" },
  { label: "Dacia", value: "dacia" },
  { label: "Daewoo", value: "daewoo" },
  { label: "Daihatsu", value: "daihatsu" },
  { label: "Datsun", value: "datsun" },
  { label: "Dodge", value: "dodge" },
  { label: "Dongfeng Motor Corporation", value: "dongfeng motor corporation" },
  { label: "Donkervoort", value: "donkervoort" },
  { label: "DR Automobiles", value: "dr automobiles" },
  { label: "DS Automobiles", value: "ds automobiles" },
  { label: "Elfin Sports Cars", value: "elfin sports cars" },
  { label: "Ferrari", value: "ferrari" },
  { label: "Fiat", value: "fiat" },
  { label: "First Automobile Works", value: "first automobile works" },
  { label: "Freightliner", value: "freightliner" },
  { label: "Ford", value: "ford" },
  { label: "Fornasari", value: "fornasari" },
  { label: "Geely", value: "geely" },
  { label: "General Motor Company", value: "general motor company" },
  { label: "Gillet Automobiles", value: "gillet automobiles" },
  { label: "Ginetta Cars", value: "ginetta cars" },
  { label: "GMC", value: "gmc" },
  { label: "Gordon", value: "gordon" },
  { label: "Great Wall Motors", value: "great wall motors" },
  { label: "Hauser", value: "hauser" },
  { label: "Hindustan Motors", value: "hindustan motors" },
  { label: "Hispano-Suiza", value: "hispano suiza" },
  { label: "Holden", value: "holden" },
  { label: "Honda", value: "honda" },
  { label: "Hurtan", value: "hurtan" },
  { label: "Hyundai", value: "hyundai" },
  { label: "Infiniti", value: "infiniti" },
  { label: "Isuzu", value: "isuzu" },
  { label: "Iveco", value: "iveco" },
  { label: "JAC Motors", value: "jac motors" },
  { label: "Jaguar", value: "jaguar" },
  { label: "Jeep", value: "jeep" },
  { label: "Karma Automotive", value: "karma automotive" },
  { label: "Kia", value: "kia" },
  { label: "Koenigsegg", value: "koenigsegg" },
  { label: "KTM", value: "ktm" },
  { label: "Lada", value: "lada" },
  { label: "Lamborghini", value: "lamborghini" },
  { label: "Lancia", value: "lancia" },
  { label: "Land Rover", value: "land rover" },
  { label: "Lexus", value: "lexus" },
  { label: "Lifan", value: "lifan" },
  { label: "Lincoln Motor Company", value: "lincoln motor company" },
  { label: "Local Motors", value: "local motors" },
  { label: "Lotus Cars", value: "lotus cars" },
  { label: "Luxgen", value: "luxgen" },
  { label: "Mahindra", value: "mahindra" },
  { label: "Maruti Suzuki", value: "maruti suzuki" },
  { label: "Maserati", value: "maserati" },
  { label: "Mastretta", value: "mastretta" },
  { label: "Mazda", value: "mazda" },
  { label: "McLaren", value: "mclaren" },
  { label: "Mercedes-Benz", value: "mercedes benz" },
  { label: "Mini", value: "mini" },
  { label: "Mitsubishi", value: "mitsubishi" },
  { label: "Mitsuoka", value: "mitsuoka" },
  { label: "Morgan Motor Company", value: "morgan motor company" },
  { label: "MPM", value: "mpm" },
  { label: "Nissan", value: "nissan" },
  { label: "Noble", value: "noble" },
  { label: "Opel", value: "opel" },
  { label: "Otra Marca", value: "otra marca" },
  { label: "Pagani Automobili", value: "pagani automobili" },
  { label: "Panoz", value: "panoz" },
  { label: "Peugeot", value: "peugeot" },
  { label: "PGO Automobiles", value: "pgo automobiles" },
  { label: "Polestar", value: "polestar" },
  { label: "Porsche", value: "porsche" },
  { label: "Proton", value: "proton" },
  { label: "Radical Sportscars", value: "radical sportscars" },
  { label: "Rely", value: "rely" },
  { label: "Renault", value: "renault" },
  { label: "Riich", value: "riich" },
  { label: "Rimac Automobili", value: "rimac automobili" },
  { label: "Roewe", value: "roewe" },
  { label: "Rolls-Royce Motor Cars", value: "rolls royce motor cars" },
  { label: "Rossion", value: "rossion" },
  { label: "Ruf", value: "ruf" },
  { label: "SAIC Motor", value: "saic motor" },
  { label: "Saleen", value: "saleen" },
  { label: "Samsung", value: "samsung" },
  { label: "Sbarro", value: "sbarro" },
  { label: "Seat", value: "seat" },
  { label: "SeAZ", value: "seaz" },
  { label: "Skoda", value: "skoda" },
  { label: "Smart", value: "smart" },
  { label: "Spania GTA", value: "spania gta" },
  { label: "SsangYong", value: "ssangyong" },
  { label: "Subaru", value: "subaru" },
  { label: "Suzuki", value: "suzuki" },
  { label: "TATA Motors", value: "tata motors" },
  { label: "Tesla", value: "tesla" },
  { label: "Thunder Power", value: "thunder power" },
  { label: "Tianjin", value: "tianjin" },
  { label: "Toyota", value: "toyota" },
  { label: "Tramontana", value: "tramontana" },
  { label: "TVR", value: "tvr" },
  {
    label: "Ulianovski Avtomobilny Zavod",
    value: "ulianovski avtomobilny zavod",
  },
  { label: "Vauxhall Motors", value: "vauxhall motors" },
  { label: "Venturi Automobiles", value: "venturi automobiles" },
  { label: "Volkswagen", value: "volkswagen" },
  { label: "Volvo", value: "volvo" },
  { label: "Vühl", value: "vuhl" },
  { label: "W Motors", value: "w motors" },
  { label: "Westfield Sportscars", value: "westfield sportscars" },
  { label: "ZAZ Auto", value: "zaz auto" },
];