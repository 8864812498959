import { useNavigate } from 'react-router-dom';

import { ButtonCustom, TextCustom } from '../../atoms';
import Telefono from '../../../assets/icons/Telefono-Asistencia.png';
import { rutasCotizar } from '../../../common/constants';
import { useApp, useCotizador } from '../../../hooks/others';
import { changeQueryParam } from '../../../core/utils';
import { useMemo } from 'react';
import ModalSeguroViaje from '../ModalSeguroViaje';

const Asistencia = ({ icon, showModal, setShowModal }) => {
  const navigate = useNavigate();

  const { seguros } = useApp();
  const {
    dataCotizar,
    setPaso,
    setDataCotizar,
    setCargando,
    setComprandoOtroSeguro,
    setMostrarCampos,
    setPasoAnterior,
    setSwitchMostrarCampos
  } = useCotizador();

  const ruta = useMemo(
    () =>
      rutasCotizar.find(ruta => ruta.codigo === dataCotizar.seguro.codigo)
        ?.ruta,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const seguro = useMemo(
    () => seguros.find(seguro => seguro.codigo === dataCotizar.seguro.codigo),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const simularSetTimeout = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const handleNavigatePasoInicial = async () => {
    setCargando(true);

    //setear valores del state global a valores iniciales
    setDataCotizar(prevState => ({
      ...prevState,
      // datosPersonales: '',
      cotizacion: '',
      poliza: '',
      pago: '',
      dataSeguro: '',
    }));
    await simularSetTimeout();
    navigate(ruta, { state: seguro });
    changeQueryParam('paso', 1);
    setPaso(1);
    setPasoAnterior(6);
    setCargando(false);
    setComprandoOtroSeguro(true);
  };
  const handleNavigatePasoPersonal = async () => {
    setCargando(true);
    setComprandoOtroSeguro(true);
    setMostrarCampos(false);

    // setear valores del state global a valores iniciales
    setDataCotizar(prevState => ({
      ...prevState,
      pago: '',
      poliza: '',
      cotizacion: '',
      datosPersonales: '',
    }));

    setSwitchMostrarCampos(false)
    await simularSetTimeout();

    navigate(ruta, { state: seguro });
    changeQueryParam('paso', 2);

    setPaso(2);
    setCargando(false);
  };

  return (
    <>
      <div className="items-resumen mt-10 flex gap-5 items-center py-1 px-2">
        <div className="w-1/3">
          <div className="flex justify-center lg:justify-end lg:mr-5">
            <img src={Telefono} alt="Telefono emergencia" className="w-20" />
          </div>
        </div>

        <div className="w-2/3 ">
          <TextCustom
            text="Guarda este contacto, si requieres asistencia en tu viaje"
            className="text-sm fontPRegular color-primary text-left md:text-base"
            component="p"
            variant="p"
          />
          <div className="flex flex-col md:flex-row sm:gap-3">
            <TextCustom
              text="Universal Assistance/INS"
              className="text-sm fontPSemiBold color-secondary text-left md:text-base"
              component="p"
              variant="p"
            />
            <a
              href="https://wa.me/5491167502557"
              className="text-sm fontPSemiBold color-primary text-left underline md:text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              +54 9 116750-2557
            </a>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center md:flex-row gap-5 md:justify-center">
        <ButtonCustom
          text="Ir al inicio"
          variant="outlined"
          typeColor="general"
          className="w-full md:w-[180px]"
          onClick={handleNavigatePasoInicial}
        />
        <ButtonCustom
          text="Contratar otro"
          typeColor="general"
          className="w-full md:w-[180px]"
          onClick={handleNavigatePasoPersonal}
        />
      </div>

      <ModalSeguroViaje
        icon={icon}
        showModal={showModal}
        setShowModal={setShowModal}
        handleNavigatePasoPersonal={handleNavigatePasoPersonal}
      />
    </>
  );
};

export default Asistencia;
