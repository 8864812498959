import { useEffect, useState } from 'react';

// Components
import { AlertCustom, TextCustom } from '../../atoms';

// Hooks
import { useCotizador, useForm } from '../../../hooks/others';

// Styles
import './styles.css';
import Asistencia from './Asistencia';
import { getFechasPago } from './utils';
import { apiCrearBitacora, apiGetEtapa } from '../../../services/apis';
import { amber } from '@mui/material/colors';

const PasoConfirmacion = () => {
  // Assets
  const IconoVehiculo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Big.png';
  const IconoViaje =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Big.svg';
  const IconoEnfermedades =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Big.svg';
  const IconoVehiculoDone =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Done-Cotizar.webp';
  const IconoEnfermedadesDone =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Done-Cotizar.webp';
  const IconoViajeDone =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Done-Cotizar.webp';
  const IconoOncologicoDone =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
  const IconoVidaPlusDone =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Icono-Small.webp';
  const IconoResultado =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Listo.webp';
  const IconoPlenisalud =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Plenisalud/Icono-Big.svg';

  const { dataCotizar, paso, setCargando, poliza } =
    useCotizador();

  const [showModal, setShowModal] = useState(false);
  const [showAlerta, setShowAlert] = useState(false);

  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });

  const { setPasoAnterior, setPasoActual, pasoActual, formaPagoSelect } =
    useCotizador();

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso, pasoActual, setPasoActual, setPasoAnterior]);

  useEffect(() => {
    if (paso === 6) {
      setCargando(false);
      setAlert({
        title: 'Pago exitoso',
        description: 'Tu pago ha sido realizado con éxito. ¡Gracias!',
        severity: 'success',
      });

      setShowAlert(true);
      setShowModal(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 15000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso]);

  const seguroIconMap = {
    'SEG-DA': IconoVehiculo,
    'SEG-V': IconoViaje,
    'SEG-AEG': IconoEnfermedades,
    'SEG-ONC': IconoOncologicoDone,
    'SEG-VID': IconoVidaPlusDone,
    'SEG-PLENI': IconoPlenisalud,
  };

  const seguroIconDone = {
    'SEG-DA': IconoVehiculoDone,
    'SEG-V': IconoViajeDone,
    'SEG-AEG': IconoEnfermedadesDone,
    'SEG-ONC': IconoEnfermedadesDone,
    'SEG-VID': IconoEnfermedadesDone,
    'SEG-PLENI': IconoPlenisalud,
  };

  const IconComponent =
    seguroIconMap[dataCotizar.seguro.codigo] || IconoResultado;

  const IconComponentDone =
    seguroIconDone[dataCotizar.seguro.codigo] || IconoResultado;

  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={showAlerta}
        setOpen={setShowAlert}
        severity={alert.severity}
      />
      <div className="pt-12 pb-12 md:pt-6 xl:pt-0 mx-auto flex flex-col items-center justify-center alto-pantalla">
        <div className="container-icon-resultado">
          <img
            src={IconComponent}
            alt="IconoResultado"
            className={`iconoResultado-${dataCotizar.seguro.codigo}`}
          />
        </div>

        <TextCustom
          text={`¡Tu ${dataCotizar.seguro.nombre} está listo!`}
          variant="h1"
          component="h2"
          className="fontPSemiBold color-primary text-center mt-8 px-4 text-[19px] xs:text-[21px] md:text-3xl lg:text-[40px] "
        />
        <div className="space-y-2 mt-5 px-4">
          <p className="fontPRegular text-center text-[13px]  xxxs:text-sm xs:text-base md:text-lg">
            ¡
            <span className="font-bold">
              {dataCotizar.datosPersonales.primerNombre}{' '}
              {dataCotizar.datosPersonales.primerApellido}
            </span>
            , tu pago ha sido realizado con éxito! <br /> Recibirás tu factura y
            tu póliza a través de correo electrónico:{' '}
            <span className="font-bold">
              {dataCotizar.datosPersonales.correo}
            </span>
          </p>

        </div>

        
        {formaPagoSelect !=="Anual" ? (
           <div className="flex gap-[10px] pt-9 items-center justify-center ">
           <div className="flex flex-col pr-[36px]">
             <TextCustom
               text={ formaPagoSelect === "Semestral" ? "Tu próximo pago" :  "Tus próximos pagos"}
               className="fontPRegular font-bold text-primary w-[176px]"
             />

             <span className="fontPRegular text-sm text-primary">{`Periodo: ${formaPagoSelect}`}</span>
           </div>
           {getFechasPago(formaPagoSelect).map((item, index) => (
             <div
               key={index}
               className={
                 formaPagoSelect === 'Mensual' && item.pago === 0
                   ? 'w-2'
                   : 'fontPRegular text-sm text-primary mt-1 rounded-md bg-contrast-content shadow-md w-[157px] flex items-center justify-center h-[34px]'
               }
             >
               <div className="flex gap-1">
                 <div>
                   <span
                     className={
                       item.label === '...'
                         ? 'font-bold '
                         : 'font-bold text-[14px] '
                     }
                   >
                     {item.label}{' '}
                   </span>
                 </div>

                 <div
                   className={
                     formaPagoSelect === 'Mensual' && item.pago === 0
                       ? 'w-2'
                       : ''
                   }
                 >
                   <span className='fontPRegular text-[12px]'> {item.pago !== 0 ? `/ Pago ${item.pago}` : ''}</span>
                 </div>
               </div>
             </div>
           ))}
         </div>

        ): null}      

       

        <div className="mt-10 text-center w-3/4 mx-auto md:w-full">
          <p className="fontPRegular text-center text-[13px] xxxs:text-xs xs:text-sm md:text-base">
            La documentación de tu seguro le será enviada por <b>INS</b> en el
            transcurso del día. <br className="hidden md:block" /> Por favor,
            asegúrate de guardar esta información:
          </p>

          <div className="flex flex-col md:flex-row md:justify-center gap-4 mt-5">
            <div className="items-resumen">
              <TextCustom
                text={`Confirmación: ${dataCotizar.pago?.autorizacion}`}
                className="fontPSemiBold color-primary text-base"
              />
            </div>
            <div className="items-resumen">
              <TextCustom
                text={`Consecutivo: ${dataCotizar.pago?.consecutivo}`}
                className="fontPSemiBold color-primary text-base"
              />
            </div>
            <div className="items-resumen">
              <TextCustom
                text={`Póliza: ${poliza}`}
                className="fontPSemiBold color-primary text-base"
              />
            </div>
          </div>

          {dataCotizar.seguro.codigo === 'SEG-V' && (
            <Asistencia
              icon={IconComponent}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}

          <div className="mt-16 flex text-center gap-3 justify-center items-center py-5 flex-col-reverse px-8 md:px-14 md:text-left md:block md:py-4 rounded-[20px] relative bg-gradient-custom ">
            <p className="fontPRegular text-sm md:text-base mt-20 md:mt-0 text-contrast-content">
              ¡Gracias por confiar en Asegurame y UNISERSE!{' '}
              <br className="hidden md:block" />
              Si tienes consultas no dudes en contactarnos:{' '}
              <a
                href={'https://wa.me/50686473773'}
                target="_blank"
                rel="noopener noreferrer"
                className="fontPRegular text-sm md:text-base mt-20 md:mt-0 text-contrast-content"
              >
                (5O6) 8647-3773
              </a>
            </p>
            <img
              src={IconComponentDone}
              alt="IconoResultadoDone"
              className="absolute icon-done"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PasoConfirmacion;
