import { generarParamsOportunidad } from "../../core/utils";
import { apiActualizarOportunidad, apiEmitirPoliza } from "../../services/apis";



export const usePago = (dataCotizar, formaPagoRef, setAlert, setCargando, recaptchaRef, plan, idOportunidad, idCotizacion, setModal) =>{
    const pasoEmision = async () => {
        try {
          setCargando(true);
          const requisitosCodigos = { AEP: '', DDP: '', DDPO: '' };
    
          let infoPoliza = '';
    
          const token = await recaptchaRef.current.executeAsync();
    
          if (dataCotizar.seguro.codigo === 'SEG-ONC') {
            requisitosCodigos.AEP = 'aceptado';
            requisitosCodigos.DDP = 'aceptado';
            requisitosCodigos.DDPO = 'aceptado';
          } else {
            requisitosCodigos.AEP = 'aceptado';
            requisitosCodigos.DDP = 'aceptado';
          }
    
          if (token) {
            //*generar poliza
            const paramsEmisionPoliza = {
              plan: { _id: plan._id },
              dataPersonal: dataCotizar.datosPersonales,
              dataSeguro: dataCotizar.dataSeguro,
              cotizacion: dataCotizar.cotizacion,
              seguro: dataCotizar.seguro,
              oportunidadId: idOportunidad,
              tokenReCaptcha: token,
              formaPago: formaPagoRef.current,
            };
    
            const { data: dataPoliza, success } =
              await apiEmitirPoliza(paramsEmisionPoliza);
    
            //* quiere decir que le emision de la poliza se realizo correctamente y luego se avanza al paso de pago
            const formaPago = formaPagoRef.current;
            if (success) {
              let paramsOportunidad = generarParamsOportunidad(
                idOportunidad,
                idCotizacion,
                'En espera la confirmación de pago para finalizar la emisión de la póliza.',
                'COTIZADOR',
                'PAG',
                'EMI',
                {
                  poliza: dataPoliza.numeroPoliza,
                },
              );
    
              paramsOportunidad = {
                ...paramsOportunidad,
                formaPagoSelect: formaPago,
              };
    
              infoPoliza = dataPoliza.numeroPoliza;
    
              await apiActualizarOportunidad(paramsOportunidad);
            } //en caso de que la poliza no se realizo correctamente
            else {
              setAlert({
                title: dataPoliza.response.data?.error ?? 'Error',
                description:
                  dataPoliza.response.data?.message ??
                  'Ha ocurrido un error al emitir la poliza',
                severity: 'error',
              });
              setModal(true);
    
              const paramsOportunidad = generarParamsOportunidad(
                idOportunidad,
                idCotizacion,
                'Hubo un error al emitir la poliza',
                'ERROR',
                'EMI',
                'EMI',
                requisitosCodigos,
              );
    
              await apiActualizarOportunidad(paramsOportunidad);
            }
          }
    
          return infoPoliza;
        } catch (error) {
          console.error(error);
        }
      };

      return pasoEmision

}