import { useEffect, useState } from 'react';
import ReactGA from 'react-ga'; // Por ningun motivo se debe borrar esta linea
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

// Components
import {
  Home,
  Informativa,
  Conocenos,
  Error404,
  Blog,
  BlogDetalle,
  Cotizar,
  PreguntasFrecuentes,
  Contacto,
  FormularioCotizar,
  Error500,
  Proximamente,
  TerminosCondiciones,
} from '../../pages';
import { Layout } from '../../templates';

// Hooks
import { AppProvider, CotizadorProvider } from '../../../hooks/context';

// Styles
import '../../styles/fonts.css';
import '../../styles/colors.css';

// Constantes
import { rutasCotizar } from '../../../common/constants';
import { changeSEO } from '../../../core/utils';

const LayoutWithMeta = () => {
  const location = useLocation();

  useEffect(() => {
    const updateSEO = async () => {
      await changeSEO();
    };

    updateSEO();
  }, [location.pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/conocenos" element={<Conocenos />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detalle" element={<BlogDetallePage />} />
        <Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/contactanos" element={<Contacto />} />
        <Route path="/deducible-vehiculo" element={<Informativa />} />
        <Route path="/enfermedades-graves" element={<Informativa />} />
        <Route path="/seguro-viaje" element={<Informativa />} />
        <Route path="/seguro-oncologico-plus" element={<Informativa />} />
        <Route path="/seguro-plenisalud" element={<Informativa />} />
        <Route path="/seguro-vida-plus" element={<Informativa />} />

        <Route
          path="/cotizar"
          element={
            <CotizadorProvider>
              <Cotizar />
            </CotizadorProvider>
          }
        />
        {rutasCotizar.map(ruta => (
          <Route
            key={ruta.codigo}
            path={ruta.ruta}
            element={
              <CotizadorProvider>
                <FormularioCotizar />
              </CotizadorProvider>
            }
          />
        ))}
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="*" element={<Navigate to="/page-error-404" replace />} />
      </Routes>
    </Layout>
  );
};

function BlogDetallePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const url = queryParams.get('url');

  return <BlogDetalle url={url} />;
}

const AppRouter = () => {
  const [proximamente, setProximamente] = useState(
    process.env.REACT_APP_proximamente,
  );

  useEffect(() => {
    setProximamente(process.env.REACT_APP_proximamente);
    if (process.env.REACT_APP_google === 'true') {
      //ReactGA.initialize('G-D97PGN5C4M');
    }
  }, []);

  return (
    <Router>
      <AppProvider>
        {proximamente ? (
          <Routes>
            <Route path="/home" element={<Proximamente />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        ) : (
          <LayoutWithMeta />
        )}
      </AppProvider>
    </Router>
  );
};
export default AppRouter;
