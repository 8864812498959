import { useEffect, useState } from 'react';

import InfoIconOpen from '@mui/icons-material/Info';
import InfoIconClose from '@mui/icons-material/Error';
//import InfoIconClose from '@mui/icons-material/ErrorOutline';
//import InfoIconClose from '@mui/icons-material/ErrorRounded';
//import InfoIconClose from '@mui/icons-material/DeleteForeverTwoTone';
//import InfoIconClose from '@mui/icons-material/InfoSharp';
//import InfoIconClose from '@mui/icons-material/InfoTwoTone';
//import InfoIconClose from '@mui/icons-material/ErrorOutlineTwoTone';

//Componentes
import { PreguntasForm, TitulosCotizador } from '../../molecules';
import { ButtonCustom, AlertCustom } from '../../atoms';

//assets
import { ReactComponent as FlechaIcon } from '../../../assets/icons/Icono-Flecha.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/Alerts/AlertCrossIcon.svg';

//hooks
import { useCotizador, useForm } from '../../../hooks/others';

// Utils
import {
  calcularDiasEntreFechas,
  changeQueryParam,
  renderFondoSeguro,
} from '../../../core/utils';

//functios
import { dataInitialForm } from '../../../core/validations/dataInitialForm';
// Services

import { apiGetInfoVehiculo, apiPostVehiculo } from '../../../services/apis';

import CollapseFormVehiculo from '../CollapseFormVehiculo';
import {
  filtrarPreguntasVehiculo,
  capitalizarPalabra,
  capitalizarMultiplesPalabras,
} from '../../../core/utils/functions';

const PasoInicial = () => {
  //* context
  const {
    dataCotizar,
    paso,
    pasos,
    handleSiguientePaso,
    setDataCotizar,
    setSwitchMostrarCampos,
    setMostrarCampos,
    isRecuperandoCotizacion,
    setComprandoOtroSeguro,
    setPasoActual,
    setPasoAnterior,
    pasoActual,
    setSwitchMoneda,
    setBienes,
    comprandoOtroSeguro
  } = useCotizador();

  // Filtra las preguntas que no deseas mostrar
  const preguntasFiltradas = (pasos[paso - 1]?.preguntas || []).filter(
    pregunta => !['Año', 'Marca', 'modeloVehiculo'].includes(pregunta.value),
  );

  //* state
  const [preguntasCollapseVehiculo, setPreguntasCollapseVehiculo] = useState(
    [],
  );
  const [formData, setFormData] = useState({});
  const [formDataError, setFormDataError] = useState('');
  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [detallesSeguro, setDetallesSeguro] = useState({});

  const [puedeCotizar, setPuedeCotizar] = useState(true);

  const [showCollapse, setShowCollapse] = useState(false);
  const [infoVehiculo, setInfoVehiculo] = useState({
    Marca: '',
    Año: '',
    modeloVehiculo: '',
  });

  const [error404, setError404] = useState(false);
  const [loaderPlaca, setLoaderPlaca] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [claseVehiculo, setClaseVehiculo] = useState('');
  const [placaVehiculo, setPlacaVehiculo] = useState('');

  const [detalleMsgErrors, setDetalleMsgErrors] = useState({
    marca: false,
    anio: false,
    modelo: false,
    propietario: false,
  });

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso]);

  useEffect(() => {
    if (paso === 1) {
      const initialFormData = dataInitialForm(dataCotizar.seguro.codigo);
  
      setPreguntasCollapseVehiculo(
        filtrarPreguntasVehiculo(pasos[paso - 1]?.preguntas),
      );
      // Si hay datos recuperados, los fusiona con la estructura inicial
      const mergedFormData = {
        ...initialFormData,
        ...dataCotizar.dataSeguro,
      };

      const areFormDataEqual = JSON.stringify(formData) === JSON.stringify(mergedFormData);
  
      if (!areFormDataEqual) {
        setFormData(mergedFormData);
        setFormDataError(pasos[paso - 1]?.formDataError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCotizar.seguro.codigo, paso, pasos]);


  useEffect(() => {
    if (paso === 1 && isRecuperandoCotizacion) {
      changeQueryParam('paso', paso);
    }
  }, [paso, isRecuperandoCotizacion]);

  useEffect(() => {
    if (paso === 1) {
      if (isRecuperandoCotizacion) {
        setSwitchMostrarCampos(true);
        setMostrarCampos(false);
      } else {
        setDataCotizar({
          ...dataCotizar,
          dataSeguro: { ...formData },
          datosPersonales: '',
        });

        setSwitchMostrarCampos(false);
        setMostrarCampos(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, paso, isRecuperandoCotizacion]);

  const handleMoneda = codigo => {
    const moneda = dataCotizar.seguro.monedas.find(
      moneda => moneda.codigo === codigo,
    );

    const planes = dataCotizar.productos.find(
      moneda => moneda.moneda.codigo === codigo,
    );

    dataCotizar.planes = planes.planes;

    setSwitchMoneda({
      codigo: moneda.codigo,
      simbolo: moneda.simbolo,
    });
  };
  //Funcion que limpia el data para los campos createdAt, updateAt y __V para que no se validen en los formularios
  const cleanData = data => {
    const { createdAt, updatedAt, __v, ...cleanedData } = data;
    return cleanedData;
  };

  const handleValue = async (val = '', valForm) => {
    let nuevaPlacaVehiculo = placaVehiculo;
    let nuevaClaseVehiculo = claseVehiculo;
    if (valForm === 'placa' || valForm === 'tipoVehiculo') {
      if (valForm === 'placa') {
        nuevaPlacaVehiculo = val;
        setPlacaVehiculo(nuevaPlacaVehiculo);
      }

      if (valForm === 'tipoVehiculo') {
        nuevaClaseVehiculo = val === 'Particular' ? 'PART' : 'CL';
        setClaseVehiculo(nuevaClaseVehiculo);
      }

      if (
        (nuevaPlacaVehiculo !== '' &&
          nuevaPlacaVehiculo.length === 6 &&
          nuevaClaseVehiculo !== '') ||
        (nuevaClaseVehiculo !== '' &&
          nuevaPlacaVehiculo !== '' &&
          nuevaPlacaVehiculo.length === 6)
      ) {
        setLoaderPlaca(true);
        const { data, success, statusCode, cotiza } = await apiGetInfoVehiculo({
          placa: nuevaPlacaVehiculo,
          clase: nuevaClaseVehiculo,
        });
        const cleanedData = cleanData(data);

        if (success) {
          setFormDataError('');
          setDetallesSeguro(cleanedData);
          setDetallesSeguro({
            Marca: capitalizarMultiplesPalabras(cleanedData.Marca || cleanedData.marca),
            Año: data.Año || cleanedData.anio,
            Modelo: capitalizarPalabra(cleanedData.Modelo || cleanedData.modelo),
          });
          setLoaderPlaca(false);
          setDisabled(true);

          const objetoVehiculo = {
            Marca: capitalizarMultiplesPalabras(cleanedData.marca || cleanedData.Marca),
            Año: data.anio || cleanedData.Año,
            modeloVehiculo: capitalizarPalabra(cleanedData.modelo || cleanedData.Modelo),
            Propietario: data.propietario,
          };

          setInfoVehiculo({ ...formData, ...objetoVehiculo });
          setFormData(prev => ({ ...prev, [valForm]: val, ...objetoVehiculo }));
          setShowCollapse(true);
          setError404(false);

          setBienes({ id_vehiculo: data.id_vehiculo, creado: data.creado });

          //Verificacion de clase
          if (!cotiza) {
            setPuedeCotizar(false);
            setAlert({
              title: '¡Ocurrio un error!',
              description: 'Por favor, contáctenos.',
              severity: 'warning',
            });
            setShowAlert(true);
          } else {
            setPuedeCotizar(true);
          }

          return;
        } else {
          setDisabled(false);
          setShowCollapse(true);
          setLoaderPlaca(false);
        }

        // No encontró el vehículo
        if (statusCode === 400 || statusCode === 404) {
          setLoaderPlaca(false);
          setDisabled(false);
          setInfoVehiculo({});
          setFormData(prev => ({ ...prev, [valForm]: val }));
          setDetallesSeguro({ modeloVehiculo: claseVehiculo });
          setPreguntasCollapseVehiculo([]);

          setShowCollapse(false);
          setError404(true);

          return;
        }
        // Error en el servidor
        if (statusCode === 500) {
          setAlert({
            title: '¡Error al buscar tu vehículo!',
            description:
              'Ha ocurrido un error para encontrar tu vehículo. Inténtalo de nuevo.',
            severity: 'error',
          });
          setShowAlert(true);
          setShowCollapse(false);
          setLoaderPlaca(false);
        }
      }
    }

    if (valForm === 'tipoMoneda') {
      let codigo;
      if (val === 'Colones') {
        codigo = 'CRC';
      } else {
        codigo = 'USD';
      }
      handleMoneda(codigo);
    }

    if (formDataError[valForm + 'Error'] && val) {
      setFormDataError({ ...formDataError, [valForm + 'Error']: false });
    }
    let newData = { ...formData, ...dataCotizar.dataSeguro };
    newData[valForm] = val;
    setFormData(newData);
  };

  //* esta funcion valida de que todos datos hayan pasado la validacion y asi proceder al siguiente paso
  const handleSubmit = async () => {
    if(comprandoOtroSeguro){
      setSwitchMostrarCampos(false)
    }
    setComprandoOtroSeguro(false);
    if (handleValidForm() && puedeCotizar) {
      setFormDataError('');
      if (Object.keys(detallesSeguro).length > 0) {
        const clase = formData.tipoVehiculo === 'Carga Liviana' ? 'CL' : 'PART';

        let params = {};
        if (showCollapse) {
          params = {
            marca: formData?.Marca,
            modelo: formData?.modeloVehiculo,
            anio: formData?.Año,
            placa: formData.placa,
            clase: clase,
            propietario: formData.Propietario,
            carroceria: '',
          };
          setDetallesSeguro({ Marca: params.marca, Modelo: params.modelo });
        } else {
          params = {
            placa: formData.placa,
            clase: clase,
            carroceria: '',
          };
          setDetallesSeguro({ placa: formData.placa });
        }

        const { success, data } = await apiPostVehiculo(params);

        if (success) {
          setAlert({
            title: '¡Vehiculo Registrado!',
            description: 'Para más información contactenos.',
            severity: 'success',
          });

          setBienes({ id_vehiculo: data.id_vehiculo, creado: data.creado });
        }
        dataCotizar.dataSeguro = { ...formData, ...detallesSeguro };
      } else {
        dataCotizar.dataSeguro = formData;
      }

      handleSiguientePaso();
    }
  };

  //* funcion que se encarga de validar los datos
  const handleValidForm = () => {
    let isValid = true;
    let validaciones = {};
    let dataKeys = Object.keys(formData);
    dataKeys = dataKeys.filter(
      item =>
        item !== 'Marca' &&
        item !== 'Año' &&
        item !== 'modeloVehiculo' &&
        item !== 'Propietario' &&
        item !== 'Modelo'
    );

    for (let i = 0; i < dataKeys.length; i++) {
      let valor = formData[dataKeys[i]];

      if (dataKeys[i] === 'fechaViaje') {
        const fechaInicial = valor.split('-').at(0);
        const fechaFinal = valor.split('-').at(1);

        const diasViaje = calcularDiasEntreFechas(fechaInicial, fechaFinal, 1);

        if (diasViaje > 180) {
          setAlert({
            title: '¡Hasta 180 días de cobertura!',
            description:
              'La duración máxima permitida para tu seguro de viaje es de 180 días. Ajusta la duración para obtener tu cotización.',
            severity: 'warning',
          });

          setShowAlert(true);
          return false;
        } else {
          setAlert({
            title: '',
            description: '',
            severity: 'info',
          });
          setShowAlert(false);
        }
      }
      if (typeof valor === 'string' && valor.length < 6) {
        validaciones = { ...validaciones, [dataKeys[i] + 'Error']: true };
      } else if (
        !['Marca', 'Año', 'modeloVehiculo', 'Propietario', '_v'].includes(
          dataKeys[i],
        )
      ) {
        if (!valor) {
          validaciones = { ...validaciones, [dataKeys[i] + 'Error']: true };
        } else {
          validaciones = { ...validaciones, [dataKeys[i] + 'Error']: false };
        }
      }
    }

    setFormDataError(validaciones);
    setDetalleMsgErrors(validaciones);

    const error = Object.entries(validaciones).filter(
      ([, value]) => value === true,
    );

    if (Object.prototype.hasOwnProperty.call(validaciones, '__vError')) {
      delete validaciones._vError;
    }

    if (error.length > 0) {
      isValid = false;
    }

    return isValid;
  };

  const handleScroll = () => {
    const btn = document.getElementById('cont_Scroll');
    btn.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'center',
    });
  };

  return (
    <div
      className="bg-degradado min-w-full"
      style={{ minHeight: 'calc(100vh - 125px)' }}
    >
      <AlertCustom
        open={showAlert}
        setOpen={setShowAlert}
        description={alert.description}
        severity={alert.severity}
        title={alert.title}
      />
      <div
        className={`contenedor-seguro-principal mt-[120px] md:mt-[100px] relative`}
      >
        <div className="grid w-full h-full items-center grid-titulo-seguro titulo-paso-inicial">
          <TitulosCotizador
            codigo={dataCotizar.seguro.codigo}
            descripcion={dataCotizar.seguro.descripcion}
            color="color-primary"
          />
          <div
            className={`bg-seguro ${renderFondoSeguro(
              dataCotizar.seguro.codigo,
            )}`}
          ></div>
        </div>

        <FlechaIcon
          className="absolute animate-bounce right-1/2 bottom-5 cursor-pointer font-bold hidden lg:block"
          onClick={handleScroll}
        />
      </div>
      <div
        className="mb-0 py-16 text-center contenedor-preguntas-form-1"
        id="cont_Scroll"
      >
        {/* renderizando las preguntas que se deben de mostrar en este paso */}
        {preguntasFiltradas.map((pregunta, index) => (
          <PreguntasForm
            titulo={pregunta.titulo}
            tipoDato={pregunta.tipo_dato.tipo}
            valueForm={pregunta.value}
            action={handleValue}
            mostrarError={formDataError[pregunta.value + 'Error']}
            msgError={pregunta.msgError}
            placeholder={pregunta.placeholder}
            respuestas={pregunta?.respuestas}
            key={index}
            className={
              pregunta.value === 'placa' && showCollapse
                ? 'justify-center items-center text-center pb-5'
                : 'form-center '
            }
            descripcion={pregunta?.descripcion}
            value={dataCotizar.dataSeguro[pregunta.value]}
            alignError="center"
            loader={loaderPlaca}
            textAlignPlaca={true}
          />
        ))}

        {error404 && (
          <div className=" ">
            <p className="fontPSemiBold color-secondary">
              <InfoIconOpen
                fontSize="small"
                sx={{
                  fill: 'var(--color-secondary)',
                }}
              />
              ¡No encontramos tu vehículo!
              <InfoIconClose
                fontSize="small"
                sx={{
                  fill: 'var(--color-secondary)',
                  stroke: 'none',
                  strokeWidth: 0,
                  border: 'none',
                  outline: 'none',
                }}
              />
            </p>
            <p className="fontPRegular">
              Podemos emitir tu póliza, pero ten en cuenta <br /> que solo
              aseguramos vehiculos particulares, <br /> camionetas o pick-ups,
              no buses ni camiones
            </p>

            <p className="fontPRegular pt-5 pb-5">
              Haz clic en Calcular para continuar
            </p>
          </div>
        )}

        {showCollapse && (
          <CollapseFormVehiculo
            preguntas={preguntasCollapseVehiculo}
            formData={formData}
            setFormData={setFormData}
            setDetalle={setDetallesSeguro}
            detalleMsgErrors={detalleMsgErrors}
            setDetalleMsgErrors={setDetalleMsgErrors}
          />
        )}

        <ButtonCustom text="Calcular" width="180px" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default PasoInicial;
