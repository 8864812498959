import { Link } from 'react-router-dom';

export const preguntasAsegurame = [
  {
    titulo: 'Asegurame',
    codigo: 'ASEG',
    datos: [
      {
        pregunta: '¿Qué es Asegurame?',
        respuesta: (
          <span>
            Es una plataforma que permite de manera rápida, conveniente y
            segura; cotizar, comparar y adquirir seguros con Aseguradoras
            debidamente inscritas ante la Superintendencia General de Seguros de
            Costa Rica.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Quién es mi asesor?',
        respuesta: (
          <span>
            UNISERSE a través de su equipo de Corredores debidamente acreditados
            y con vasta experiencia, así como todo el equipo de soporte serán
            tus asesores.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Asegurame tiene algún costo?',
        respuesta: (
          <span>
            No, Asegurame no tiene ningún costo para los usuarios. <br />{' '}
            Nuestra plataforma genera ingresos a través de las comisiones que
            pagas las Aseguradoras a Uniserse, por su gestión tecnológica,
            promoción, gestión y asesoría a los asegurados que nos eligen para
            adquirir sus seguros.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿En qué países opera Asegurame?',
        respuesta: (
          <span>
            Asegurame opera en Costa Rica y Honduras; expandiendo sus
            capacidades a otros países de la región. <br /> Nuestro objetivo es
            que nuestra tecnología de la mano de Corredurías con probadas
            capacidades técnicas y de servicio, nos permitan llevar nuestro
            servicio a más personas en la región.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Cómo contratar mi seguro?',
        respuesta: (
          <span>
            La contratación es completamente digital para muchos de los
            productos, mas para algunos otros donde la experiencia digital aún
            no está disponible serán los Corredores y el equipo de Uniserse
            quienes te contactarán para asesorarte, guiarte y acompañarte en la
            elección y contratación del seguro.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿En qué momento empieza a cubrir el seguro?',
        respuesta: (
          <span>
            Tan pronto se complete el proceso definido y se realice el pago del
            seguro, recibirás la confirmación por parte de la Aseguradora y dará
            inicio el amparo del seguro contratado.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Cuál es el método de pago?',
        respuesta: (
          <span>
            Al ser un proceso digital de suscripción de seguros, el pago por
            Tarjeta de Crédito es el método seguro definido por las Aseguradoras
            para completar el proceso de suscripción. <br /> Para los seguros
            donde la experiencia digital aun no está disponible, el pago puede
            realizarse con Tarjeta de Crédito o Transferencia Electrónica a las
            cuentas de la Aseguradora.
          </span>
        ),
        estado: false,
      },
    ],
  },
  {
    titulo: 'Seguro de Deducible de Auto',
    codigo: 'SEG-DA',
    datos: [
      {
        pregunta: '¿Qué es un seguro de deducible de automóvil?',
        respuesta: (
          <span>
            Es una novedosa póliza complementaria al seguro principal que ampara
            el vehículo, ideada para reintegrarte el rebajo que la Aseguradora
            realiza al pagarte un reclamo por concepto del deducible
            pactado/elegido.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Cómo funciona el seguro de deducible de automóvil?',
        respuesta: (
          <span>
            El seguro se activar al comprobarse la rebaja del deducible en una
            indemnización pagada.
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Cuáles son los beneficios de tener un seguro de deducible de automóvil?',
        respuesta: (
          <span>
            <ul>
              <li>Tiene un costo anual accesible</li>
              <li>
                Reduce tu participación económica ante la ocurrencia de un
                evento asegurado
              </li>
              <li>
                Complementa de manera ideal la póliza principal de seguro que
                ampara tu vehículo, sin importar con cual aseguradora haya sido
                suscrita esa póliza.
              </li>
              <li>
                Se puede utilizar en 2 eventos durante la vigencia anual del
                seguro, para las coberturas de Responsabilidad Civil o Daño
                Directo.
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Quién debería considerar obtener un seguro de deducible de automóvil?',
        respuesta: (
          <span>
            <ul>
              <li>
                Toda persona que posea un vehículo asegurado o este pensando en
                asegurarlo.
              </li>
              <li>Que además pueda destinar ₵96 por día</li>
              <li>
                Que desee eliminar o minimizar la participación (deducible) de
                su póliza ante la ocurrencia de un incidente amparado por la
                póliza.
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Qué NO está amparado en el seguro de Deducibles?',
        respuesta: <span>Este seguro no cuenta con exclusiones.</span>,
        estado: false,
      },
      {
        pregunta:
          '¿Cómo hago efectivo este seguro de deducible de automóvil en caso de adquirirlo?',
        respuesta: (
          <span>
            Sólo debe comunicarse con el equipo de Gestión de Indemnizaciones de
            UNISERSE a través de cualquiera de nuestras plataformas{' '}
            <Link
              to="https://linktr.ee/uniserse"
              className="underline font-semibold"
              target="_blank"
            >
              (https://linktr.ee/uniserse)
            </Link>{' '}
            para recibir la asesoría y gestión ante la Aseguradora del reclamo.
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Qué Aseguradora brinda respaldo a este seguro de deducible de automóvil?',
        respuesta: (
          <span>
            Esta póliza es ofrecida por el INSTITUTO NACIONAL SEGUROS (INS) y el
            producto se encuentra debidamente registrado ante SUGESE según el
            código de registro de producto G11-22-A01-1031.
          </span>
        ),
        estado: false,
      },
    ],
  },
  {
    titulo: 'Seguro de Enfermedades Graves',
    codigo: 'SEG-AEG',
    datos: [
      {
        pregunta: '¿Qué es un seguro de enfermedades graves?',
        respuesta: (
          <span>
            Es un seguro que brinda amparo al ser diagnosticado por primera vez
            de alguna de las siguientes Enfermedades Graves:
            <ul>
              <li>
                Cáncer (debe diagnosticarse inequívocamente con la confirmación
                histopatológica)
              </li>
              <li>Accidente Cerebro Vascular</li>
              <li>Insuficiencia renal</li>
              <li>Infarto miocardio</li>
            </ul>
            <br />
            Adicionalmente brinda amparo por:
            <ul>
              <li>Indemnización adicional por muerte</li>
              <li>Renta diaria por hospitalización</li>
              <li>Indemnización adicional por cirugía reconstructiva</li>
              <li>Asistencia Medicina Virtual</li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Cuál es la diferencia entre un seguro de enfermedades graves y un seguro de salud tradicional?',
        respuesta: (
          <span>
            Es un seguro autoexpedible, simplificado por la Aseguradora para que
            su compra sea expedita y sencilla. <br />
            Se enfoca en brindar amparo a una serie de diagnósticos específicos,
            mientras que en un Seguro de Salud tradicional brinda amparo amplio
            a toda enfermedad y padecimiento diagnosticado, así como a
            honorarios médicos, medicamentos, gastos hospitalarios y
            ambulatorios, terapias, etc.
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Cuál es la ventaja de tener un seguro de enfermedades graves?',
        respuesta: (
          <span>
            <ul>
              <li>Fácil suscripción y compra</li>
              <li>
                Un costo por el seguro muy inferior a un seguro de salud
                tradicional
              </li>
              <li>
                Brinda un ayuda y alivio económico al ser diagnosticado por
                primera vez por alguna de las Enfermedades Graves amparadas por
                el seguro
              </li>
              <li>
                Se constituye en un importante apoyo para tu recuperación y
                bienestar
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Qué NO está amparado en el seguro de enfermedades graves?',
        respuesta: (
          <span className="list-QF">
            Recomendamos leer las Condiciones Generales de este seguro,
            inscritas ante la SUGESE bajo el código de registro de producto
            P20-62-A01-923, mas para facilidad las hemos transcrito aquí: <br />
            <ul>
              <li>
                Condiciones o situaciones existentes previas a la contratación
                del seguro que afecten el interés que se asegura.
              </li>
              <li>
                Gastos médicos originados por servicios y/o prestaciones
                médicas.
              </li>
              <li>
                Diagnósticos o cirugías realizadas por médicos no colegiados y/o
                realizadas en hospitales o clínicas no autorizadas por el
                Ministerio de Salud y en el caso de que el diagnóstico fuera
                hecho en el extranjero, los centros de atención deben contar con
                las acreditaciones correspondientes.
              </li>
              <li>
                El cáncer relacionado con radiación, reacción atómica o
                contaminación radioactiva.
              </li>
              <li>
                Cirugías estéticas o plásticas con fines de embellecimiento,
                aunque sea originada por un cáncer cubierto como enfermedad
                grave por esta póliza.
              </li>
              <li>
                Diagnóstico efectuados por el mismo Asegurado, cónyuge o algún
                miembro cercano de su familia hasta el tercer grado de
                consanguinidad o afinidad, una persona que conviva con él, aún y
                cuando sea un Médico Especialista.
              </li>
              <li>
                Cáncer in situ, tumores debidos al virus de inmunodeficiencia
                humana (VIH), el cáncer de piel a excepción de melanoma maligno.
              </li>
              <li>
                Leucemia diferente a la leucemia linfocítica crónica si no
                existe diseminación generalizada de células leucémicas en la
                médula ósea formadora de sangre.
              </li>
              <li>
                Leucemia linfocítica crónica en etapa anterior a Estadio I de
                RAI o Estadio A-1 de Binet.
              </li>
              <li>
                Melanomas y todos los cánceres de piel, a menos que haya
                evidencia de metástasis o el tumor sea un melanoma maligno mayor
                de 1.5 mm de espesor máximo determinado por el examen
                histológico utilizando el método Breslow.
              </li>
              <li>Micro-carcinoma papilar de la tiroides.</li>
              <li>
                Cáncer papilar no invasor de la vejiga histológicamente descrito
                como TaN0M0 o con una clasificación menor.
              </li>
              <li>
                Tumores benignos, tumores encapsulados, carcinomas in situ y/o
                el cáncer donde no se haya roto la pared del epitelio basal.
              </li>
              <li>
                Agravaciones de la enfermedad grave, por causa o como
                consecuencia del consumo de alcohol, estupefacientes, sustancias
                alucinógenas, drogas tóxicas o heroicas ingeridas
                voluntariamente por la persona asegurada, que no hayan sido por
                prescripción médica.
              </li>
              <li>
                Intervenciones médicas o tratamientos con drogas o materiales
                que se encuentren en procesos de experimentación, cualquiera que
                sea su etapa de desarrollo.
              </li>
              <li>
                Suicidio, intento de suicidio o lesiones autoinfligidas en los
                términos del artículo 101 de la Ley Reguladora del Contrato de
                Seguros, que cometa el Asegurado.
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Tiene la póliza algún período de tiempo durante el cual la aseguradora no indemnice o pueda disputar la indemnización?',
        respuesta: (
          <span>
            Si, la aseguradora no pagará si el diagnóstico por una enfermedad
            grave ocurre en los primeros seis (6) posteriores a la emisión del
            Seguro; además si durante los primeros dos (2) años del
            perfeccionamiento del seguro se determina que la enfermedad que
            origina la indemnización es preexistente a la emisión y el evento
            ocurre durante el plazo indicado la Aseguradora quedará liberada de
            su responsabilidad de pagar.
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Cómo hago efectivo este seguro de enfermedades graves en caso de adquirirlo?',
        respuesta: (
          <span>
            Sólo debe comunicarse con el equipo de Gestión de Indemnizaciones de
            UNISERSE a través de cualquiera de nuestras plataformas{' '}
            <Link
              to="https://linktr.ee/uniserse"
              className="underline font-semibold"
              target="_blank"
            >
              (https://linktr.ee/uniserse)
            </Link>{' '}
            para recibir la asesoría, acompañamiento y gestión ante la
            Aseguradora del reclamo.
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Qué Aseguradora brinda respaldo a este seguro de enfermedades graves?',
        respuesta: (
          <span>
            Esta póliza es ofrecida por el INSTITUTO NACIONAL SEGUROS (INS) y el
            producto se encuentra debidamente registrado ante SUGESE según el
            código de registro de producto P20-62-A01-923.
          </span>
        ),
        estado: false,
      },
    ],
  },
  {
    titulo: 'Seguro de Viaje',
    codigo: 'SEG-V',
    datos: [
      {
        pregunta: '¿Qué es un seguro de viaje?',
        respuesta: (
          <span>
            Es una forma de protección que puedes adquirir antes de realizar un
            viaje en calidad de turista, estudiante o ejecutivo para estar
            cubierto ante imprevistos y situaciones que puedan afectar su salud,
            vida, así como otros incidentes definidos en la póliza.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Qué cubre un seguro de viaje?',
        respuesta: (
          <span>
            Este tipo de seguro entre sus amparos ofrece lo siguiente:
            <ul>
              <li>Muerte del Asegurado</li>
              <li>
                Pérdida de extremidades por separación física arriba de la
                muñeca o tobillo o pérdida total e irrecuperable de la vista de
                uno o ambos ojos.
              </li>
              <li>Incapacidad total y permanente del Asegurado</li>
              <li>Gastos Médicos por Accidente</li>
              <li>
                Gastos Médicos producto de una enfermedad aguda o una emergencia
                médica (ver definiciones en glosario)
              </li>
              <li>
                Gastos por repatriación a Costa Rica de los restos mortales
              </li>
              <li>Repatriación Sanitaria</li>
              <li>Perdida de pasaporte</li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Por qué es importante tener un seguro de viaje?',
        respuesta: (
          <span>
            <ul>
              <li>Te cubre las 24 horas mientras estés de viaje</li>
              <li>
                Te brinda auxilio ante situaciones imprevistas que podrían
                arruinar tu viaje y generar gastos significativos
              </li>
              <li>
                Te brinda acceso a servicios de asistencia médica de calidad
              </li>
              <li>Existen múltiples opciones para adaptar el costo</li>
              <li>
                Puede brindar amparo en una de sus opciones a temas pandémicos
              </li>
              <li>Hay países donde es obligatorio tenerlo para ingresar</li>
              <li>
                Te brinda acceso a la asesoría y al acompañamiento que Asegurame
                y Uniserse brindan
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Cómo puedo contratar mi seguro de viaje?',
        respuesta: (
          <span>
            ¡Tres simples pasos en Asegurame y tu seguro estará listo!
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Qué NO está amparado en el seguro de viaje?',
        respuesta: (
          <span>
            Recomendamos leer las Condiciones Generales de este seguro,
            inscritas ante la SUGESE bajo el código de registro de producto
            P19-57-A01-972, mas a continuación indicamos las más relevantes:
            <ul>
              <li>
                Condiciones o situaciones existentes previas a la contratación
                del seguro que afecten el interés que se asegura.
              </li>
              <li>
                Gastos incurridos por accidente, emergencia médica o enfermedad
                aguda como consecuencia de la práctica profesional de deportes
              </li>
              <li>Diagnóstico y tratamiento de enfermedades venéreas</li>
              <li>
                Tratamiento preventivo y/o vacunaciones, salvo que sean
                consecuencia de un accidente cubierto.
              </li>
              <li>
                Participación en competencias o en pruebas de seguridad,
                resistencia, regularidad, velocidad, así como resultado de la
                práctica o prueba de deportes extremos
              </li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta:
          '¿Cómo hago efectivo este seguro de viaje en caso de adquirirlo?',
        respuesta: (
          <span>
            Debe comunicarse con la Unidad de Asistencia de la Aseguradora para
            activar el seguro, o bien comunicarse con el equipo de Gestión de
            Indemnizaciones de UNISERSE a través de cualquiera de nuestras
            plataformas{' '}
            <Link
              to="https://linktr.ee/uniserse"
              className="underline font-semibold"
              target="_blank"
            >
              (https://linktr.ee/uniserse)
            </Link>{' '}
            para recibir la asesoría y acompañamiento
          </span>
        ),
        estado: false,
      },
      {
        pregunta: '¿Qué Aseguradora brinda respaldo a este seguro de viaje?',
        respuesta: (
          <span>
            Esta póliza es ofrecida por el INSTITUTO NACIONAL SEGUROS (INS) y el
            producto se encuentra debidamente registrado ante SUGESE según el
            código de registro de producto P19-57-A01-972.
          </span>
        ),
        estado: false,
      },
    ],
  },
  {
    titulo: 'Seguro Oncológico Plus',
    codigo: 'SEG-ONC',
    datos: [
    {
      pregunta: 'Si compro la póliza y me detectan cáncer ¿estoy cubierto?',
      respuesta: (
        <span>
          El Instituto concederá al Asegurado un período de gracia de sesenta (60) 
          días naturales a partir de la fecha estipulada de pago, sin recargo de intereses, para pagar la prima.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Qué vigencia tiene esta póliza?',
      respuesta: (
        <span>
          La póliza tiene una vigencia de un año contado a partir de la fecha de suscripción, 
          entendiéndose prorrogable obligatoria e indefinidamente por períodos anuales, 
          salvo que el asegurado exprese lo contrario con al menos treinta (30) días de anticipación 
          a la fecha de vencimiento.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Qué requisitos se necesitan para contratar la póliza?',
      respuesta: (
        <span>
          Tener 18 (dieciocho) años o más, completar los formularios y requisitos que correspondan y 
          realizar el pago consecuente.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Qué cubre un seguro oncológico?',
      respuesta: (
        <span>
          El Instituto pagará la suma asegurada seleccionada en la Oferta de Seguro al Asegurado en caso 
          de que sea diagnosticado por primera vez de Cáncer.<br></br>
          El Cáncer debe diagnosticarse inequívocamente con la confirmación histopatológica.<br></br>
          El Instituto pagará la suma asegurada adicional, en el caso de que el cáncer diagnosticado al Asegurado sea:
         <ul>
          <li>En mujeres por cáncer: Vaginal, de útero (incluido el de cérvix), de mama, de vulva y de ovario.</li>
          <li>En hombres: De Próstata.</li>
         </ul>
        </span>
      ),
      estado: false,
    },
    
  
  ],
  },
  {
    titulo: 'Seguro Plenisalud',
    codigo: 'SEG-PLENI',
    datos: [
    {
      pregunta: '¿Qué es PleniSalud?',
      respuesta: (
        <span>
          PleniSalud es un seguro médico integral que cubre consultas, hospitalización, medicamentos y 
          otros servicios médicos para el bienestar del asegurado y su familia.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Qué cubre PleniSalud?',
      respuesta: (
        <span>
          PleniSalud cubre consultas médicas, procedimientos ambulatorios, hospitalización, medicamentos,
          exámenes de laboratorio y más.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿PleniSalud cubre hospitalización?',
      respuesta: (
        <span>
          Sí, cubre la hospitalización en habitación individual o compartida, incluyendo cuidados intensivos.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Cuándo puedo acceder al chequeo general?',
      respuesta: (
        <span>
          El chequeo médico está disponible a partir de la primera renovación anual de la póliza.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿PleniSalud incluye cobertura para medicamentos?',
      respuesta: (
        <span>
          Sí, cubre los medicamentos prescritos para enfermedades o accidentes cubiertos.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿PleniSalud tiene deducible?',
      respuesta: (
        <span>
          PleniSalud no tiene deducible para la red primaria de salud, lo que significa que no hay costos adicionales
          para servicios de atención médica básica. Sin embargo, en algunos servicios especializados o procedimientos
          de alta complejidad, puede aplicarse un coaseguro del 20%. 
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Cómo funciona el servicio de medicina virtual?',
      respuesta: (
        <span>
          A través de la app INS 24/7, se ofrecen consultas médicas virtuales ilimitadas sin costo adicional.
        </span>
      ),
      estado: false,
    },
    {
      pregunta: '¿Cuándo empieza a cubrir PleniSalud?',
      respuesta: (
        <span>
          La cobertura inicia inmediatamente después del pago y confirmación de la póliza por el INS.
        </span>
      ),
      estado: false,
    },
  
  ],
  },
  /* {
    titulo: 'Seguro de Vida Plus',
    codigo: 'SEG-VID',
    datos: [],
  }, */
];
