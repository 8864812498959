import { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Utils
import {
  changeQueryParam,
  functionSearchParamsPasoToNumber,
  renderForm,
} from '../../core/utils';
import { apiRecuperarCotizacion } from '../../services/apis';


export const CotizadorContext = createContext();

const CotizadorProvider = ({ children }) => {
  const location = useLocation();

  // staste
  const [isRecuperandoCotizacion, setIsRecuperandoCotizacion] = useState(false);
  const [paso, setPaso] = useState(1);
  const [numPasos, setNumPasos] = useState(1);
  const [pasos, setPasos] = useState([]);
  const [rechazado, setRechazado] = useState(false);
  const [plan, setPlan] = useState({});
  const [idCotizacion, setIdCotizacion] = useState(null);
  const [idOportunidad, setIdOportunidad] = useState(null);
  const [detallesCotizacion, setDetallesCotizacion] = useState([]);
  const [poliza, setPoliza] = useState('')
  const [bienes, setBienes] = useState({})
  const [dataCotizar, setDataCotizar] = useState({
    seguro: '',
    datosPersonales: '',
    dataSeguro: '',
    cotizacion: '',
    planes: '',
    aliado: '',
    poliza: '',
    pago: ''
  });
  const [mostrarCampos, setMostrarCampos] = useState(false);
  const [switchMostrarCampos, setSwitchMostrarCampos] = useState(false);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [porcentajeError, setPorcentajeError] = useState(false);
  const [recuperarBeneficiarios, setRecuperarBeneficiarios] = useState(false);
  const [requisitosModales, setRequisitosModales] = useState({});
  const [sumasAseguradas, setSumasAseguradas] = useState('');
  const [cargando, setCargando] = useState(true);
  const [promocion, setPromocion] = useState({});
  const [comprandoOtroSeguro, setComprandoOtroSeguro] = useState(false);
  const [pasoActual, setPasoActual] = useState('');
  const [pasoAnterior, setPasoAnterior] = useState('');
  const [switchMoneda, setSwitchMoneda] = useState({
    codigo: '',
    simbolo: '',
  });
  const [formaPagoSelect, setFormaPagoSelect] = useState("Anual")
  const [formasPago, setFormasPago] = useState([])
  const [primas, setPrimas] = useState([])
  const [maskedData, setMaskedData] = useState('');


  const recuperarCotizacion = pasosArray => {
    const idCotizacionParametro = new URLSearchParams(location.search).get(
      'cotizacion',
    );

    apiRecuperarCotizacion({
      cotizacionId: idCotizacionParametro,
    }).then(({ data: { data } }) => {
      const pasoParametro = functionSearchParamsPasoToNumber(data.etapa);

      if(data.dataCotizar.cotizacion){
        if (data.dataCotizar.cotizacion.data.primas){
          setPrimas(data.dataCotizar.cotizacion.data.primas)
        }else{
          setPrimas(data.dataCotizar.cotizacion.data.precios)
        }
        
      }
      setFormaPagoSelect(data.formaPago)

      //seteando todo el state
      setIsRecuperandoCotizacion(true);
      setPaso(pasoParametro);

      if (pasoParametro > 1) {
        setMostrarCampos(true);
        setSwitchMostrarCampos(true);
      }

      let metodosPago = []

      metodosPago = data.dataCotizar.planes[0].data.primas ?  data.dataCotizar.planes[0].data.primas : [{id: "Anual", label: "Anual" }] ;
      const arrayOptions = metodosPago.map(item => {
        return {
          id: item.frecuenciaPago,
          label: item.frecuenciaPago,
        };
      });

      setFormasPago(prev => [
        ...prev,
        ...arrayOptions.filter(
          option =>
            !prev.some(
              existingOption => existingOption.id === option.id,
            ),
        ),
      ]);


      setPasos(pasosArray);
      setDetallesCotizacion(data.detallesCotizacion);
      data.beneficiarios.length > 0
        ? setDataCotizar({
            ...data.dataCotizar,
            datosPersonales: {
              ...data.dataCotizar.datosPersonales,
              beneficiarios: data.beneficiarios,
            },
          
          })
        : setDataCotizar({...data.dataCotizar, factEmp: data.factEmp, formasPago: data.formasPago});
      setIdCotizacion(data.cotizacionId);
      setIdOportunidad(data.oportunidadId);
      setSumasAseguradas(data.sumasAseguradas[0]?.sumaAsegurada);
      setPoliza(data.dataCotizar.poliza)

      //aqui si es por ejemplo de emision en adelante
      let numeroPasos = pasos.length;
      if (data?.dataCotizar?.cotizacion?._id) {
        if (numPasos !== 6) {
          const pasoDelPlan = data.planSeleccionado?.pasos.map(paso => {
            let values = {};
            let valuesError = {};
            if (paso?.preguntas) {
              values = paso.preguntas.reduce((acc, curr) => {
                acc[curr.value] = '';
                return acc;
              }, {});
              valuesError = paso.preguntas.reduce((acc, curr) => {
                if (curr.requerido) {
                  acc[curr.value + 'Error'] = false;
                }
                return acc;
              }, {});
            }
            let newPasos = {
              formData: values,
              formDataError: valuesError,
              formulario: renderForm(paso.id.nombre),
              nombre: paso.id.nombre,
              preguntas: paso.preguntas,
            };
            return newPasos;
          });

          if (numeroPasos < paso) {
            setPaso(pasoParametro);
          } else {
            setPaso(pasoParametro - 1);
          }

          setPasos([...pasosArray, ...pasoDelPlan]);

          setNumPasos(numeroPasos + 3);
          if (data.beneficiario?.numeroIdentificacion) {
            setRecuperarBeneficiarios(true);
          }
          setBeneficiarios(data.beneficiarios);
          //seteando el plan que seleciono el usuario
          setPlan(data.planSeleccionado);
        }
        return;
      } else if (pasoParametro > 3 && !data?.dataCotizar?.cotizacion?._id) {
        setPaso(3);
      }

      changeQueryParam('paso', pasoParametro);
    }).catch((error)=>{
      console.log(error)
    });
  };

  const resetearContext = () => {
    setSwitchMoneda({ codigo: '', simbolo: '' });
    setNumPasos(1);
    setPaso(1);
    setPasos([]);
    setDataCotizar({
      seguro: '',
      datosPersonales: '',
      dataSeguro: '',
      cotizacion: '',
      planes: [],
      aliado: '',
      poliza: '',
      pago: ''
    });
    setPlan({});
    setRechazado(false);
    setDetallesCotizacion([]);
    setRequisitosModales({});
    setIdCotizacion(null);
    setIdOportunidad(null);
    setCargando(true);
    setRecuperarBeneficiarios(false);
    setBeneficiarios([]);
    setPorcentajeError(false);
    setSumasAseguradas('');
    setIsRecuperandoCotizacion(false);
  };

  const handleValidBeneficiarios = () => {
    let arrayError = beneficiarios;
    let valid = true;
    let porcentaje = 0;
    let validIdentification = true

    if (beneficiarios.length) {
      beneficiarios.map((beneficiario, index) => {
        if (beneficiario.add) {
          if (!beneficiario.tipoIdentificacion) {
            arrayError[index].tipoIdentificacionError = true;
            valid = false;
          } else {
            arrayError[index].tipoIdentificacionError = false;
          }

          if (!beneficiario.numeroIdentificacion) {
            arrayError[index].numeroIdentificacionError = true;
            valid = false;
          } else {
            if(dataCotizar.datosPersonales.numeroIdentificacion === beneficiario.numeroIdentificacion) {
              arrayError[index].numeroIdentificacionError = false;
              validIdentification = false
            } else {
              arrayError[index].numeroIdentificacionError = true;
            }
          }

          if (!beneficiario.primerNombre) {
            arrayError[index].primerNombreError = true;
            valid = false;
          } else {
            arrayError[index].primerNombreError = false;
          }

          if (!beneficiario.primerApellido) {
            arrayError[index].primerApellidoError = true;
            valid = false;
          } else {
            arrayError[index].primerApellidoError = false;
          }

          if (!beneficiario.parentesco) {
            arrayError[index].parentescoError = true;
            valid = false;
          } else {
            arrayError[index].parentescoError = false;
          }

          if (!beneficiario.porcentaje) {
            arrayError[index].porcentajeError = true;
            valid = false;
          } else {
            arrayError[index].porcentajeError = false;
            porcentaje += +beneficiario.porcentaje;
          }
        }
        return valid;
      });

      if (porcentaje !== 100 && valid) {
        setPorcentajeError(true);
        return { error: 'invalid', porcentaje };
      } else {
        setPorcentajeError(false);
      }
      setBeneficiarios(arrayError);

      if (!validIdentification) {
        return { error: 'invalidIdentification' };
      }

      if(beneficiarios.length === 2 && beneficiarios[0].numeroIdentificacion === beneficiarios[1].numeroIdentificacion) return { error: 'invalidIdentification' };

      let errores = 0;

      for (const objeto of arrayError) {
        for (const key in objeto) {
          if (
            Object.prototype.hasOwnProperty.call(objeto, key) &&
            key.endsWith('Error') &&
            objeto[key]
          ) {
            errores++;
          }
        }
      }

      if (errores === 6 || errores === 12) {
        return { error: 'sbeneficiarios', porcentaje };
      }

      return valid;
    }

    return valid;
  };

  //funcionar para regresar de paso
  const handleRegresarPaso = () => {
    if (numPasos !== paso) {
      changeQueryParam('paso', paso - 1);
      setPaso(paso - 1);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleSiguientePaso = () => {
    if (numPasos !== paso) {
      changeQueryParam('paso', paso + 1);
      setPaso(paso + 1);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <CotizadorContext.Provider
      value={{
        primas,
        setPrimas,
        formasPago,
        setFormasPago,
        setFormaPagoSelect,
        formaPagoSelect,
        bienes,
        setBienes,
        poliza,
        setPoliza,
        paso,
        setPaso,
        numPasos,
        setNumPasos,
        dataCotizar,
        setDataCotizar,
        pasos,
        setPasos,
        resetearContext,
        plan,
        setPlan,
        idCotizacion,
        setIdCotizacion,
        detallesCotizacion,
        setDetallesCotizacion,
        idOportunidad,
        setIdOportunidad,
        mostrarCampos,
        setMostrarCampos,
        switchMostrarCampos,
        setSwitchMostrarCampos,
        rechazado,
        setRechazado,
        handleRegresarPaso,
        setBeneficiarios,
        beneficiarios,
        setPorcentajeError,
        porcentajeError,
        handleValidBeneficiarios,
        requisitosModales,
        setRequisitosModales,
        handleSiguientePaso,
        sumasAseguradas,
        setSumasAseguradas,
        isRecuperandoCotizacion,
        setIsRecuperandoCotizacion,
        recuperarCotizacion,
        cargando,
        setCargando,
        recuperarBeneficiarios,
        promocion,
        setPromocion,
        comprandoOtroSeguro,
        setComprandoOtroSeguro,
        pasoActual,
        setPasoActual,
        pasoAnterior,
        setPasoAnterior,
        switchMoneda,
        setSwitchMoneda,
        maskedData,
        setMaskedData,
      }}
    >
      {children}
    </CotizadorContext.Provider>
  );
};

export default CotizadorProvider;
