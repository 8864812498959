import dayjs from 'dayjs';
export const formValidDatosPersonales = (p, valor, dataKey) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let validaciones = [];

  if (p.value === dataKey) {
    if (p.value === 'telefono') {
      valor = valor.replaceAll('_', '');
    }
    if(p.value === "numeroIdentificacion"){
      if(valor.length < 9){
        validaciones.push({ campo: dataKey, error: true});
      }
    }
    if (p.requerido && !valor) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (p.value === 'correo' && valor && !regexEmail.test(valor)) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (p.value === 'telefono' && valor && valor.length < 9) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (
      valor &&
      p.value !== 'correo' &&
      p.value !== 'telefono' &&
      valor.length > 20
    ) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (p.value === 'fechaNacimiento') {
      const fechaNacimiento = dayjs(valor, 'DD/MM/YYYY');
      const hoy = dayjs();
      const diferenciaEnDias = hoy.diff(fechaNacimiento, 'day');

      if (diferenciaEnDias <= 0)
        validaciones.push({ campo: dataKey, error: true });
    }
  }
  return validaciones;
};
