import { Link } from 'react-router-dom';

// Components
import { TextCustom } from '../../atoms';

// Assets
import botonCerrar from '../../../assets/icons/btn-cerrar.svg';
import logoMenuMovil from '../../../assets/images/Logos/AsegurameLogoMovil.svg';

// Hooks
import { useApp } from '../../../hooks/others';
import { renderTexto, urlSeguro } from '../../molecules/DropMenuOptions';

const MobileMenu = ({ handelCloseMenu }) => {
  const { seguros } = useApp();

  // Assets
  const AsegurameMedico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Small.webp';
  const AsegurameVehiculo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Small.webp';
  const AsegurameViaje =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Small.webp';
  const AsegurameOncologicoPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
  const AseguramePlenisalud =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Plenisalud/Icono-Big.svg';

  const renderImagen = {
    'SEG-DA': (
      <img
        src={AsegurameVehiculo}
        alt="AsegurameVehiculo"
        className="w-10"
        style={{ height: '25px' }}
      />
    ),
    'SEG-V': (
      <img
        src={AsegurameViaje}
        alt="AsegurameViaje"
        style={{ width: '22px', height: '33px' }}
      />
    ),
    'SEG-AEG': (
      <img
        src={AsegurameMedico}
        alt="AsegurameMedico"
        style={{ width: '35px', height: '27px' }}
      />
    ),
    'SEG-ONC': (
      <img
        src={AsegurameOncologicoPlus}
        alt="AsegurameOncologicoPlus"
        style={{ width: '45px', height: '45px' }}
      />
    ),
    'SEG-PLENI': (
      <img
        src={AseguramePlenisalud}
        alt="AseguramePlenisalud"
        style={{ width: '45px', height: '45px' }}
      />
    ),
  };

  return (
    <div id="mobile-menu" className=" mobile-menu">
      <div className="px-[5%] py-5">
        <div className="flex justify-between">
          <Link to="/">
            <img src={logoMenuMovil} alt="Logo" className="w-44" />
          </Link>
          <div onClick={handelCloseMenu}>
            <img src={botonCerrar} alt="btn cerrar" />
          </div>
        </div>
      </div>
      <div className="grid-item-menu-mobile" onClick={handelCloseMenu}>
        <div className="pb-4">
          <TextCustom text="Seguros" className="item-menu-mobile fontPBold" />
          <hr />
          <div className="grid grid-cols-2 gap-3 pt-4">
            {seguros.map(seguro => (
              <Link
                to={urlSeguro[seguro.codigo]}
                key={seguro.codigo}
                className="item-menu-mobile fontPRegular"
              >
                <div className="flex justify-between items-center">
                  <TextCustom
                    text={
                      <>
                        <span className="text-[10px] fontPRegular ">
                          {seguro.nombre.includes('Seguro de ')
                            ? 'Seguro de'
                            : 'Seguro'}
                        </span>
                        {seguro.nombre.split('Seguro de')[1] ||
                          seguro.nombre.split('Seguro')[1]}
                      </>
                    }
                    className="color-contrast-content text-xs fontPBold w-16"
                  />
                  {renderImagen[seguro.codigo]}
                </div>
                <div className={`flex flex-col justify-center`}>
                  <TextCustom
                    text={renderTexto[seguro.codigo]}
                    className="color-contrast-content text-[10px] fontPRegular mt-2"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="py-4">
          <TextCustom text="Asegurame" className="item-menu-mobile fontPBold" />
          <hr />
          <div className="grid grid-cols-2 gap-3 pt-4">
            <Link to={'/conocenos'} className="item-menu-mobile">
              <div className="flex justify-between items-end">
                <TextCustom
                  text="Conócenos"
                  className="color-contrast-content text-xs fontPBold mt-4"
                />
              </div>
              <div className={`flex flex-col justify-center`}>
                <TextCustom
                  text="Nuestra misión es hacer los seguros más accesibles."
                  className="color-contrast-content text-[10px] fontPRegular mt-2"
                />
              </div>
            </Link>
            <Link to="/blog" className="item-menu-mobile  fontPRegular">
              <div className="flex justify-between items-end">
                <TextCustom
                  text="Blog"
                  className="color-contrast-content text-xs fontPBold mt-4"
                />
              </div>
              <div className={`flex flex-col justify-center`}>
                <TextCustom
                  text="Noticias y más para informarte del mundo de los seguros."
                  className="color-contrast-content text-[10px] fontPRegular mt-2"
                />
              </div>
            </Link>
            <Link to="/contactanos" className="item-menu-mobile  fontPRegular">
              <div className="flex justify-between items-end">
                <TextCustom
                  text="Contáctanos"
                  className="color-contrast-content text-xs fontPBold mt-4"
                />
              </div>
              <div className={`flex flex-col justify-center`}>
                <TextCustom
                  text="¡Estamos aquí para ayudarte! Comunícate con nosotros."
                  className="color-contrast-content text-[10px] fontPRegular mt-2"
                />
              </div>
            </Link>
            <Link
              to="/preguntas-frecuentes"
              className="item-menu-mobile  fontPRegular"
            >
              <div className="flex justify-between items-end">
                <TextCustom
                  text="Preguntas Frecuentes"
                  className="color-contrast-content text-xs fontPBold mt-4"
                />
              </div>
              <div className={`flex flex-col justify-center`}>
                <TextCustom
                  text="Respondemos las dudas que tengas."
                  className="color-contrast-content text-[10px] fontPRegular mt-2"
                />
              </div>
            </Link>
            <Link
              to="/terminos-condiciones"
              className="item-menu-mobile  fontPRegular"
            >
              <div className="flex justify-between items-end">
                <TextCustom
                  text="Terminos y Condiciones"
                  className="color-contrast-content text-xs fontPBold mt-4"
                />
              </div>
              <div className={`flex flex-col justify-center`}>
                <TextCustom
                  text="Conoce nuestros términos y condiciones."
                  className="color-contrast-content text-[10px] fontPRegular mt-2"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="pt-5">
          <Link
            to="/cotizar"
            className="item-menu-mobile fontPSemiBold bg-secondary w-full block text-center p-3 rounded-3xl"
          >
            Cotizar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
