export const formValidMetodoPago = data => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    nombre: '',
    numeroTarjeta: '',
    fecha: '',
    cvv: '',
    colegiado: '',
  };
  let inputsSuccess = {
    nombre: true,
    numeroTarjeta: true,
    fecha: true,
    cvv: true,
    colegiado: true,
  };

  if (!data.dataColegiado.value && data.dataColegiado.flag === true) {
    inputsError.colegiado = 'Ingresa el número de identidad del colegiado  \n';
    inputsSuccess.colegiado = false;
    isValid = false;
  }

  if (data.dataColegiado.value === data.dataColegiado.numeroIdentificacion) {
    inputsError.colegiado = 'La identidad tiene que ser diferente a la del colegiado \n';
    inputsSuccess.colegiado = false;
    isValid = false;
  }
  if (!data.nombre) {
    inputsError.nombre = 'Ingresa nombre del titular de la tarjeta \n';
    inputsSuccess.nombre = false;
    isValid = false;
  }
  if (!data.numeroTarjeta.value) {
    inputsError.numeroTarjeta = 'Ingresa el número de la tarjeta \n';
    inputsSuccess.numeroTarjeta = false;
    isValid = false;
  } else {
    if (data.numeroTarjeta.value.length < 16) {
      inputsError.numeroTarjeta = 'Ingresa los 16 dígitos de tu tarjeta \n';
      inputsSuccess.numeroTarjeta = false;
      isValid = false;
    }
  }

  if (!data.fecha.value) {
    inputsError.fecha = 'Ingresa la fecha de vencimiento de la tarjeta.\n';
    inputsSuccess.fecha = false;
    isValid = false;
  } else {
    const fecha = data.fecha.formatValue;
    if (+fecha.split('/')[0] > 12) {
      inputsError.fecha = 'Ingresa una fecha de vencimiento válida.\n';
      inputsSuccess.fecha = false;
      isValid = false;
    } else {
      if (!validarFechaMayorIgualActual(fecha)) {
        inputsError.fecha = 'Ingresa una fecha de vencimiento válida.\n';
        inputsSuccess.fecha = false;
        isValid = false;
      }
    }
  }

  if (!data.cvv) {
    inputsError.cvv = 'Ingresa CVV de la tarjeta.\n';
    inputsSuccess.cvv = false;
    isValid = false;
  }

  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};

function validarFechaMayorIgualActual(fechaIngresada) {
  const fechaActual = new Date();

  const partesFecha = fechaIngresada.split('/');
  const mesIngresado = parseInt(partesFecha[0]);
  const anoIngresado = parseInt(partesFecha[1]) + 2000;

  const fechaIngresadaObj = new Date(anoIngresado, mesIngresado - 1, 1);

  // Comparar las fechas
  if (fechaIngresadaObj >= fechaActual) {
    return true;
  } else {
    return false;
  }
}
